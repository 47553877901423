import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { TemplateService } from 'src/app/shared/services/template-service/template.service';
import { TemplateModel, ProcessModel, StageModel, AttributeModel, FileMappingModel } from 'src/app/shared/services/webclient-api'
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message-service';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/shared/services/project-service/project.service';
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { FileMappingService } from 'src/app/shared/services/file-mapping-service/file-mapping.service';
import { CommonService } from 'src/app/shared/services/common/common.service';



@Component({
  templateUrl: '../../shared/grid-component/grid.component.html',
  styleUrls: ['./template.component.css']
})

export class TemplateComponent implements OnInit, OnDestroy {
  private gridApi;
  public subscription: any;
  private gridColumnApi;
  public savedFilter: any;
  public savedSort: any;
  public context: any;
  rowData: any[] = [];
  template: TemplateModel;
  stage: any;
  process: any;
  attribute: any;
  stages: StageModel[];
  stageDetails: any;
  processes: ProcessModel[];
  atributes: AttributeModel[];
  filemappings: FileMappingModel[];
  componentTitle = AppConstants.TEMPLATETITLE;
  componentName = AppConstants.TEMPLATETITLE;
  constructor(private toastr: ToastrService, private router: Router, private projectsService: ProjectService,

    private commonService: CommonService,
    private _messageService: MessageService, private service: TemplateService, private dialog: MatDialog, public datepipe: DatePipe) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    })
    service.initializeProcessType();
    service.initializeTemplates();
    service.initializeAttributesDetails();
    service.initializeProcessDetails();
    service.initializeStageDetails();
    service.initializeLineageDetails()
    service.initializeMappingKeyValues()
    service.initializeAPIType();

  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  ngOnInit(): void {
    
    this.GetProcessTypes();
    this.loadStages();
    this.loadProcesses();
    this.loadAttributes();   
    this.loadProjects();  
    this.getTemplateInfo();
  }

  update(row) {
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      if (row.data !== null) {
        this.onclickCreateTemplate(row.data?.templateId);
      }
    }
  }
  columnDefs = [
    { headerName: 'Name', field: 'name', sortable: true, filter: true, width: 135, tooltipValueGetter: (params) => params.value },
    { headerName: 'Project | Group', field: 'projectGroup', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Description', field: 'description', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'modifiedBy', sortable: true, filter: true, width: 105, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Modified Date', field: 'modifiedDate', sortable: true, filter: true, width: 110, cellRenderer: (data) => {
        return data.value ? (new Date(data.value)).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) : '';
      }
    },
    { headerName: 'Active', field: 'isActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 75 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      width: 75,
      tooltipValueGetter: (params) => 'Edit'
    },
  ];
  onclickCreateTemplate(templateId) {
    this.router.navigate(['/Template', templateId, 0]);
  }

  add() {

    this.onclickCreateTemplate(0);
  }

  getTemplateInfo() {
    this.service.GetTemplates()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {           
            this.rowData.push({
              templateId: r.templateId,
              name: r.name,
              projectGroup:r.project?.name+" | "+ r.group?.name,
              description: r.description,
              version:r.version,
              isActive: r.isActive,
              createdDate: r.createdDate,
              createdBy: r.createdBy,
              modifiedDate: r.modifiedDate,
              modifiedBy: r.modifiedBy,
              stageDetails: r.stageDetails,
              lineageDetails:r.lineageDetails,
              group:r.group,
              project:r.project,          
              experimentTemplateId:r.experimentTemplateId,
              isExperimentTemplateModel:r.isExperimentTemplateModel
            });
          })

          
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDTEMPLATEFILTER);
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDTEMPLATESORT);
          this.service.templates = result
        },
        error => {
          console.log(error);
        }
      )
  }

  public onChange(event) {

  }
  updateStatus(templateModel: TemplateModel, isActive: boolean) {
    this.service.UpdateTemplate(templateModel, isActive)
      .subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('Template updated successfully !!');

      },
        error => {
          this.toastr.error(error, 'Template update failed !!');
        })
  }
  refreshGrid() {
    this.getTemplateInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    // this.clearSortandFilter();
    this.onCloseEvent();
  }

  refreshGridFilters() {
    //this. getApiTypes();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.clearSortandFilter();
    this.onCloseEvent();
  }
 
  loadProjects() {
    this.projectsService.getProjects(false).subscribe(
      result => {
        this.service.projectsModel = result?.filter(x => x.isActive);
      },
      error => {
        console.log(error);
      }
    );
  }
  
  loadStages() {
    this.service.GetStages().subscribe(
      result => {
        this.service.stageModel.stages = result?.filter(x => x.isActive);
      },
      error => {
        console.log(error);
      }
    );
  }
  
  GetProcessTypes() {
    this.service.GetProcessTypes().subscribe(
      result => {      
        this.service.processTypes = result;
        this.service.processTypes.unshift(this.service.processType);    
      },
      error => {
        console.log(error);
      }
    );
  }


  loadProcesses() {
    this.service.GetProcesses().subscribe(
      result => {

        this.service.processModel.processes = result?.filter(x => x.isActive);

      },
      error => {
        console.log(error);
      }
    );
  }

  loadAttributes() {
    this.service.GetAttributes().subscribe(
      result => {

        this.service.attributesModel.attributes = result?.filter(x => x.isActive);
      },
      error => {
        console.log(error);
      }
    );
  }
  clearSortandFilter() {
    this.gridColumnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
    this.gridApi.setFilterModel(null);
    this.gridApi.sizeColumnsToFit();
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(GridSizeChangedEvent) {
    GridSizeChangedEvent.api.sizeColumnsToFit();
  }

  onSortChanged(params) {
    this.savedSort = this.gridColumnApi.getColumnState();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDTEMPLATESORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDTEMPLATEFILTER, JSON.stringify(this.savedFilter));
  }

  onFirstDataRender(params) {

    if (this.savedSort != undefined && this.savedSort != null && this.savedSort.length != 0) {
      this.gridColumnApi.applyColumnState({
        state: this.savedSort,
        applyOrder: true,
      });
    }


    this.gridApi.setFilterModel(this.savedFilter);
  }
  
  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }
  
  onColumnMoved(event) {
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy() {
    try { 
        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = false;
        }

    } catch (error) {
      console.log(error);

    }
}

}
