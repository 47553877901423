import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";


import { ViewChild, OnDestroy } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { MatDialog } from '@angular/material/dialog';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { FileErrorModel } from 'src/app/shared/services/webclient-api';
import { FileProcessService } from 'src/app/shared/services/file-process-service';
import { MessageService } from 'src/app/shared/services/message-service';
import { DeleteFilesPopupComponent } from './delete-files-popup/delete-files-popup.component';

export class TreeModel {
  children: Array<TreeModel>;
  name: string;
  CreatedDate: string;
  FileMappingId: number;
  AnalystName: string;
  AnalystId: number;
  ErrorMessage: string;
  ExperimentProcessId: number;
}

@Component({
  selector: 'app-file-processing-errors',
  templateUrl: './file-processing-errors.component.html',
  styleUrls: ['./file-processing-errors.component.scss']
})
export class FileProcessingErrorsComponent implements OnInit {
  @ViewChild('tree') tree: MatTree<any>;
  TREE_DATA: TreeModel[] = [];
  data;
  selectedFiles: FileErrorModel[] = [];
  isDeleteFilesSuccess : boolean = false;
  isDeleteHappen: boolean = false;
  isFilesSelected: boolean = false;
  isFilesLoaded: boolean = false;
  
  experimentFilterControl = new FormControl();
 
    // subscription;
    count = 0;
    nestedTreeControl: NestedTreeControl<TreeModel>;
    nestedDataSource: MatTreeNestedDataSource<TreeModel>;
    inputValue;


  rowData: FileErrorModel[] = [];
  selectedExperimentValue : any;
  constructor(private service: FileProcessService, 
    private sortPipe: SortByPipe,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private _messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog) { 
      /** Data Source and Tree Control used by Tree View */
    this.nestedTreeControl = new NestedTreeControl<TreeModel>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
      this._messageService.listen().subscribe((m:any) => {
      })
    }

  ngOnInit(): void {
    this.getFileErrors();
  }

   /** Checks if datasource for material tree has any child groups */
   hasNestedChild = (_: number, nodeData: TreeModel) => nodeData.children.length > 0;

   /** Returns child groups from security group */
   private _getChildren = (node: TreeModel) => node.children;
 
   // To select individual file
   clickedActive(element) {
     element.checked = !element.checked;
     this.getSelectedFiles();
     this.isFilesSelected = this.selectedFiles.length > 0;
   }
 
   //Selects/deselects all the children
   SelectAllChildren(data, isSelected) {
     data.forEach(d => {
       d.checked = isSelected;
       if (d.children && d.children.length > 0) {
         this.SelectAllChildren(d.children, isSelected);
       }
     });
   }
 
   // Calls when Experiment checkbox selected
   clickedParentActive(element) {
     element.checked = !element.checked;
     this.SelectAllChildren(element.children, element.checked)
     this.getSelectedFiles();
     this.isFilesSelected = this.selectedFiles.length > 0;
   }
 
   /** Loops recursively through data finding the amount of checked children */
   getCheckedAmount(data) {
     this.count = 0; // resetting count
     this.loopData(data);
     return this.count;
   }
 
   /** Used by getCheckedAmount() */
   loopData(data) {
     data.children.forEach(d => {
       if (d.checked) {
         this.count += 1;
       }
       if (d.children && d.children.length > 0) {
         this.loopData(d.children);
       }
     });
     if(data.children.length === this.count)
     {
       data.checked = this.count > 0;
     }
     else
     {
       data.checked = false;
     }
   }
 
   //To prevent change detections
   ngAfterViewChecked() {
     this.cdr.detectChanges(); 
   }
 
   //Calls when open/close the group
   changeState(data) {
     data.expanded = !data.expanded;
   }
 
   //To load files in the tree
   getFileErrors(){
     this.service.GetFileErrors()
       .subscribe(
         result => {
           this.rowData = [];
           result.forEach(r => {
             let data = this.TREE_DATA.find(x=> x.name === r.fileType);
             if(data === undefined || data === null) {
               this.TREE_DATA.push({
                 name: r.fileType,
                 children: [{ name: r.fileName , children: [], FileMappingId: r.fileMappingId, ExperimentProcessId: r.experimentProcessId, AnalystId: r.analystId, AnalystName: r.analystName, ErrorMessage: r.errorMessage, CreatedDate: this.datepipe.transform(r.createdDate,'MM/dd/yyyy')}],
                 FileMappingId: 0,
                 AnalystName: '',
                 AnalystId: 0,
                 CreatedDate: '',
                 ErrorMessage: '',
                 ExperimentProcessId: 0
               })
             }
             else {
               data.children.push({ name: r.fileName, children: [], FileMappingId: r.fileMappingId, ExperimentProcessId: r.experimentProcessId, AnalystId: r.analystId, AnalystName: r.analystName, ErrorMessage: r.errorMessage, CreatedDate: this.datepipe.transform(r.createdDate,'MM/dd/yyyy')})
             }
             
           })
           const data = this.TREE_DATA;
           data.map(d => {
             d['checked'] = false;
             d['expanded'] = false;
             return d;
           });
           this.nestedDataSource.data = data;
           this.isFilesLoaded = true;
         },
         error =>{
           console.log(error);
           this.isFilesLoaded = true;
         }
       ) 
   }
 
   //Calls when Cancel button selected
   onCancel() {
     this.router.navigate([AppConstants.DASHBOARD]);
    }
 
    //Calls when Delete button selected
    deleteFiles()
    {
     this.isDeleteFilesSuccess = false;
     this.isDeleteHappen = false; 
     let dialogConfig = this.service.getDialogConfig();
     let dialogRef = this.dialog.open(DeleteFilesPopupComponent, dialogConfig);
 
     dialogRef.afterClosed().subscribe(
       status => {
         if (status === 'confirm') {
         this.getSelectedFiles();
         this.deleteSlectedFiles();
       }
   
     })
   }
 
    getSelectedFiles()
    {
     this.selectedFiles = [];
     this.nestedDataSource.data.forEach(d => {
       if (d.children && d.children.length > 0) {
         d.children.forEach(row => {
           if(row['checked'])
           {
             let fileError = new FileErrorModel({
               fileName: row.name,
               fileMappingId: row.FileMappingId,
               experimentProcessId: row.ExperimentProcessId,
               fileType: d.name,
               errorMessage: row.ErrorMessage,
               createdDate: new Date(row.CreatedDate),
               analystId: row.AnalystId,
               analystName: row.AnalystName
             });
             this.selectedFiles.push(fileError);           
           }
           
         })
       }
     });
    }
 
    deleteSlectedFiles() {
     this.service.deleteRogueFiles(this.selectedFiles)
       .subscribe(
         result => {
           this.isDeleteFilesSuccess = true;
           this.isDeleteHappen = true;
           this.isFilesSelected = false;
           this.TREE_DATA = [];
           this.getFileErrors();
         },
         error =>{
           this.isDeleteFilesSuccess = false;
           this.isDeleteHappen = true;
           }
         );
   }

}
