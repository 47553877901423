import { Component, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { TemplateService, ProcessTypes } from 'src/app/shared/services/template-service/template.service';
import { StageModel, ExperimentPlanningModel, ApiTypeModel, StageDetailModel, ProcessTypeModel, SecurityGroupModel, TemplateModel, ProcessDetailModel, FileMappingKeyValueModel, AttributeDetailModel, ProcessModel, AttributeModel, GroupModel, ProjectModel, FileMappingModel, LineageDetailModel, SubMappingRuleModel, UserFriendlyHeaderModel, EntityTypeDto, ProjectDetailsSlim } from 'src/app/shared/services/webclient-api'
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/shared/services/message-service';
import { Observable, of } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { ProjectService } from 'src/app/shared/services/project-service/project.service';
import { GroupsService } from 'src/app/shared/services/group-service/groups.service'
import { FileMappingService } from 'src/app/shared/services/file-mapping-service/file-mapping.service';
import { ExperimentService } from 'src/app/shared/services/experiment.service';
import { ClonerService } from 'src/app/shared/services/deep-clone/deep-clone.service';
import { CommonService } from 'src/app/shared/services/common/common.service';


@Component({
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})

export class CreateTemplateComponent {
  public context: any;
  componentNameNew = AppConstants.TEMPLATENEW;
  componentNameModify = AppConstants.TEMPLATEMODIFY;
  isdataCopy: boolean = false;
  isCopyButtonVisible: boolean = false;
  isSaveDisabled: boolean = true;
  fileOption: number;
  stageslst: StageModel[];
  processlst: ProcessModel[];
  atributeslst: AttributeModel[];
  template: TemplateModel = new TemplateModel();
  templateOriginal: any;
  stage: StageDetailModel = new StageDetailModel();
  process: ProcessDetailModel = new ProcessDetailModel;
  attribute: AttributeDetailModel = new AttributeDetailModel();
  filemappings: FileMappingModel[] = [];
  filemappingsAll: FileMappingModel[] = [];
  groups: GroupModel[];
  projects: ProjectModel[];
  validationErrorTxt?: string;
  filteredStages: any[];
  filteredProcess: any[];
  filteredAttributes: any[];
  isError: boolean = false;
  stages: StageModel[];
  processes: ProcessModel[];
  atributes: AttributeModel[];
  flLineage: LineageDetailModel;
  fmKeys: FileMappingKeyValueModel;
  subRules: SubMappingRuleModel[];
  fndrlHeaders: UserFriendlyHeaderModel[];
  fileMappingFilter: FileMappingModel[] = [];
  filemapping: FileMappingModel;
  tempfilemapping: FileMappingModel[];
  tempFrindlyHeaders: UserFriendlyHeaderModel[] = [];
  frdData: any;
  isAddLineageBtnDisabled: boolean = true;
  sortOrderLst: any[] = [];
  sortOrderLineage: any[] = [];
  fromExperiment: number = 0;
  templateId: number;
  proccessType = ProcessTypes;
  experimentPlanningModel: ExperimentPlanningModel;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  isTemplateNameDisabled: boolean = false;
  hasDataModified: boolean = false;
  backbuttonText: any = "";
  anyFileMappingsDeactivated = false;
  isPopupForFileMappingMissing: boolean = false;
  fileMappingActiveNames: string = null;
  fileMappingDeActiveNames: string = null;
  fileMappOriginalName: string = null;
  processDetailModelLst: ProcessDetailModel[] = [];
  processTypes: ProcessTypeModel[] = [];
  apiTypes: ApiTypeModel[];
  operationTypes: ApiTypeModel[];
  bionexusEntities: EntityTypeDto[];
  bionexusProjects: ProjectDetailsSlim[];
  strainRegistrationApiId: any;
  getStrainInfoApiId: any;
  hasProjectModified: boolean = false;

  selectedProjectId: number;
  selectedGroupId: number;
  joinDataApiId: any;
  isRestrictedRole: boolean = false;
  selectedProjectIdOld: number;
  selectedGroupIdOld: number;
  isProjectGroupAssigned: boolean = false;

  constructor(private toastr: ToastrService,
    private service: TemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private projectsService: ProjectService,
    private groupService: GroupsService,
    private fileMappinService: FileMappingService,
    public datepipe: DatePipe,
    public experimentService: ExperimentService,
    private commonService: CommonService,
    public objectClone: ClonerService,
    private _messageService: MessageService) {

    this.context = { componentParent: this };
    service.initializeAttributesDetails();
    service.initializeProcessDetails();
    service.initializeStageDetails();
    service.initializeTemplates();
    service.initializeAPIType();

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.templateId = params['id'];
      this.fromExperiment = params['fromExperiment'];
    });
    this.GetProcessTypes();
    this.loadStages();
    this.loadProcesses();
    this.loadAttributes();
    this.loadProjects();
    this.loadDependencyData();
    this.loadOperationTypes();
    this.loadBionexusEntities();
    this.loadBionexusProjects();    
    this.displayTemplate();

  }

  displayTemplate() {
    if (this.templateId != 0) {

      if (this.fromExperiment == 0) {
        this.editTemplateInfo(this.templateId);
      }
      else {
        this.getExperimentDetails(this.templateId);
        this.isCopyButtonVisible = false;
      }

    }
    else {
      this.initializeTemplate();
    }

    this.backbuttonText = this.fromExperiment == 0 ? AppConstants.GOTOTEMPLATES : this.backbuttonText = AppConstants.GOTOEXPERIMENT;
  }

  initializeTemplate() {

    if (this.service.stageModel !== undefined)
      this.stage = this.service.stageModel;

    if (this.stage !== undefined && this.service.processModel !== undefined)
      this.stage?.processDetails.push(this.service.processModel);

    if (this.service.templatesModel !== undefined) {

      this.template = this.service.templatesModel;
      this.selectedProjectId = this.template.projectId;
      this.selectedGroupId = this.template.groupId;
      // fill the the API type and File Mappings dropdown based on selected project and group
      if (this.selectedProjectId != null && this.selectedProjectId != 0 && this.selectedGroupId != null && this.selectedGroupId != 0) {
        this.getMappingDetails(this.selectedProjectId, this.selectedGroupId);
      }
      this.template.stageDetails.push(this.stage);
      let stage = 1;

      this.template.stageDetails.forEach(stg => {
        let srtOrd = { sortOrder: stage }
        this.sortOrderLst.push(srtOrd);
        stage = stage + 1;
        stg.stages = this.stageslst;
        let prsOrder = 1;
        stg.processDetails.forEach(prs => {
          prs.sortOrder = prs.sortOrder === null ? prsOrder : prs.sortOrder;
          prsOrder++;
          prs.processes = this.processlst;
          prs.filteredFileMappingsList = this.filemappings;
          prs.filteredSourcesList = this.apiTypes;
        })
      });
    }
    this.isAddLineageEnabled();
  }

  scrollEvent = (event: any): void => {
    this.autoComplete?.updatePosition();
  };

  loadDependencyData() {
    this.loadFriendlyHeaders();
  }

  valueChange() {
    this.hasDataModified = true;
  }

  isGroupSelected: boolean = false;
  groupSelectionChange(groupId) {
    this.selectedGroupId = groupId;
    this.reLoadMappingDetails(this.selectedProjectId, this.selectedGroupId)
    this.hasDataModified = true;
    this.isGroupSelected = true;   
  }



  getMappingDetails(projectId, groupId) {
    this.service.getApiTypesByProjectGroupId(projectId, groupId)
      .subscribe(result => {
        this.service.apiTypes = result;
        this.apiTypes = this.service.apiTypes
        this.fileMappinService.getFileMappingsByProjectGroupId(projectId, groupId)
          .subscribe(async result => {
            this.service.fileMappingsModel = result
            this.filemappings = this.service.fileMappingsModel;
            this.template.stageDetails.forEach(stg => {
              stg.processDetails.forEach(prs => {
                prs.filteredFileMappingsList = this.filemappings;
                prs.filteredSourcesList = this.apiTypes;
                this.onFileMappingChange(false);
              })
            });
          },
            error => {
              this.toastr.error('File mapping load failed !!');
            });
      },
        error => {
          this.toastr.error('Api type loading failed !!');
        });
  }

  projectSelectionChange(projectId) {
    this.selectedProjectId = projectId;
    this.hasDataModified = true;
    this.hasProjectModified = true;
    this.template.groupId = 0;
    this.loadGroups(projectId)
  }
  // re-load the API type and File Mappings dropdown based on selected project and group
  reLoadMappingDetails(projectId, groupId) {
    this.service.getApiTypesByProjectGroupId(projectId, groupId)
      .subscribe(result => {
        this.service.apiTypes = result;
        this.apiTypes = this.service.apiTypes

        this.fileMappinService.getFileMappingsByProjectGroupId(projectId, groupId)
          .subscribe(async result => {
            this.service.fileMappingsModel = result
            this.filemappings = this.service.fileMappingsModel;
            this.template.stageDetails.forEach(stg => {
              stg.processDetails.forEach(prs => {
                prs.filteredFileMappingsList = this.filemappings;
                prs.filteredSourcesList = this.apiTypes;
              })
            });
            let messageText = "";
            if ((this.filemappings == undefined) || (this.filemappings != undefined && this.filemappings.length == 0)) {
              messageText = AppConstants.FILEMAPPING
            } else if ((this.apiTypes == undefined) || (this.apiTypes != undefined && this.apiTypes.length == 0)) {
              messageText = AppConstants.APITYPEMAPPING
            }

            if (messageText.length > 0) {
              let dialogConfig = this.service.getDialogConfig();
              let headerMessage = "Error";
              let messageType = "Info"
              dialogConfig.data = { message: messageText, headerMessage: headerMessage, messageType: messageType };
              this.dialog.open(StatusDialogComponent, dialogConfig);
            }
          },
            error => {
              this.toastr.error('File mapping load failed !!');
            });
      },
        error => {
          this.toastr.error('Api type loading failed !!');
        });

  }


  editTemplateInfo(tempId) {
    this.isTemplateNameDisabled = true;
    this.isCopyButtonVisible = false;
    this.isPopupForFileMappingMissing = false;
    this.isGroupSelected = true;
    if (tempId != 0) {
      this.service.GetTemplateById(tempId)
        .subscribe(
          result => {
            this.template = result;
            this.hasProjectModified = true;
           
            this.loadGroups(this.template.projectId);
            this.getMappingDetails(this.template.projectId, this.template.groupId);
            this.selectedProjectIdOld = this.template.projectId;
            this.selectedGroupIdOld = this.template.groupId;
            this.selectedProjectId = this.template.projectId;
            this.selectedGroupId = this.template.groupId;
            this.templateOriginal = this.objectClone.deepClone(result)
            if (this.projects.find(x => x.projectId === this.template.project.projectId) === undefined) {
              this.projects.push(this.template.project);
            }
            var order = 1;
            result.stageDetails.forEach(x => {
              let srtOrd = { sortOrder: x.sortOrder === null ? order : x.sortOrder }
              order = order + 1;
              this.sortOrderLst.push(srtOrd);
            });

            let lineageSortOrder = 1;
            result.lineageDetails = result.lineageDetails.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });
            result.lineageDetails.forEach(x => {
              let srtOrdLineage = { sortOrder: x.sortOrder === null ? lineageSortOrder : x.sortOrder }
              lineageSortOrder = lineageSortOrder + 1;
              this.sortOrderLineage.push(srtOrdLineage);
            });

            this.template.stageDetails.forEach(element => {
              let prsOrder = 1;
              element.stages = this.service.stageModel?.stages;
              element.processDetails?.forEach(prs => {
                prs.sortOrder = prs.sortOrder === null ? prsOrder : prs.sortOrder;
                prsOrder = prsOrder + 1;
                
                if(prs.processTypeId === ProcessTypes.FieldConcatenation && prs.apiTypeId === null)
                  prs.apiTypeId = this.joinDataApiId;
                prs.processes = this.service.processModel?.processes;
                prs.filteredFileMappingsList = this.filemappings;
                prs.filteredSourcesList = this.apiTypes;
                if (prs?.attributeDetails != undefined && prs?.attributeDetails?.length > 0) {
                  prs.attributeDetails?.forEach(att => {
                    att.attributes = this.service.attributesModel?.attributes;
                  });
                }
                if (prs.processTypeId == 1 || prs.processTypeId == 2) {
                  this.checkMissingFileMapping(prs.fileMappingId);
                }
              });
              this.onFileMappingChange(false);
            });


            if (result != null) {
              this.isCopyButtonVisible = true;
            }
            this.checkFileMapDeactivated();
            this.checkTemplateUsedInExperiment(this.template.templateId);
          },
          error => {
            console.log(error);
          })

         
    }
  }



  getExperimentDetails(experimentPlanningId) {
    this.isTemplateNameDisabled = true;
    this.experimentService.GetExperimentById(experimentPlanningId)
      .subscribe(
        result => {
          this.template = result.templateModel;
          this.templateOriginal = this.objectClone.deepClone(result.templateModel)
          this.template.name = result.templateModel?.name
          this.template.templateId = result.templateModel?.experimentTemplateId
          if (this.template.projectId != null && this.template.projectId != 0 && this.template.groupId != null && this.template.groupId != 0) {
            this.loadGroups(this.template.projectId);
            this.getMappingDetails(this.template.projectId, this.template.groupId);
            this.isRestrictedRole = this.commonService.applyRole(this.template?.projectId, this.template?.groupId)
            this.isGroupSelected = true;
            let lineageSortOrder = 1;
            result.templateModel.lineageDetails = result.templateModel.lineageDetails.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });
            result.templateModel.lineageDetails.forEach(x => {
              let srtOrdLineage = { sortOrder: x.sortOrder === null ? lineageSortOrder : x.sortOrder }
              lineageSortOrder = lineageSortOrder + 1;
              this.sortOrderLineage.push(srtOrdLineage);
            });

            var order = 1;
            result.templateModel.stageDetails.forEach(x => {
              let srtOrd = { sortOrder: x.sortOrder === null ? order : x.sortOrder }
              order++;
              this.sortOrderLst.push(srtOrd);
            });

            this.template.stageDetails.forEach(element => {
              element.isStageDisable = true;
              let prsOrder = 1;
              element.stages = this.service.stageModel?.stages;
              element.processDetails?.forEach(prs => {
                prs.sortOrder = prs.sortOrder === null ? prsOrder : prs.sortOrder;
                prsOrder++;

                prs.processes = this.service.processModel?.processes;
                prs.filteredFileMappingsList = this.filemappings;
                prs.filteredSourcesList = this.apiTypes;

                if (prs?.attributeDetails != undefined && prs?.attributeDetails?.length > 0) {
                  prs.attributeDetails?.forEach(att => {
                    att.attributes = this.service.attributesModel?.attributes;
                  });
                }
              });
              this.onFileMappingChange(false);
            });
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Get experiment details failed !!');
        }

      );
  }
  addNewStage() {

    let srtnbr = this.template.stageDetails?.length + 1;
    this.process = new ProcessDetailModel({
      processDetailId: 0,
      stageDetailId: 0,
      processId: 0,
      name: null,
      processTypeId: null,
      canModifyProcess: true,
      isFileAssigned: false,
      turnAroundTime: 0,
      fileMappingId: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      processes: this.processlst,
      filteredFileMappingsList: this.filemappings,
      filteredSourcesList: this.service.apiTypes,
      attributeDetails: [],
      fileMapping: null
    });


    this.stage = new StageDetailModel({
      stageDetailId: 0,
      templateId: 0,
      stageId: 0,
      name: null,
      isActive: true,
      isStageDisable: false,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      sortOrder: srtnbr,
      stages: this.stageslst,
      processDetails: []
    });

    this.stage.processDetails.push(this.process)
    this.stage.processDetails.forEach(prs => {
      prs.processes = this.processlst;
      prs.filteredFileMappingsList = this.filemappings,
        prs.filteredSourcesList = this.service.apiTypes;
    })
    this.template.stageDetails.push(this.stage);
    //sort order list
    let SrtNm = this.sortOrderLst.length + 1
    let srord = { sortOrder: SrtNm }
    this.sortOrderLst.push(srord)
  }



  deleteStage(stgIndex) {
    this.hasDataModified = true;
    this.template.stageDetails[stgIndex]?.processDetails.forEach(x => {

      (x.processTypeId == 1 || x.processTypeId == 2)
      {
        var count = this.template.stageDetails?.filter(n => n.processDetails?.find(y => y.fileMappingId == x.fileMappingId && (x.processTypeId == 1 || x.processTypeId == 2))).length;


        if (count == 1) {
          var frdrRcords = this.template.stageDetails[stgIndex]?.processDetails.find(y => y.fileMappingId == x.fileMappingId);
          if (frdrRcords != null) {
            let frdhrdindx = this.fileMappingFilter.indexOf(frdrRcords);
            this.fileMappingFilter.splice(frdhrdindx, 1);
          }
        }
      }
    });
    // re-ordering sequence number after deleting stage
    this.template.stageDetails.splice(stgIndex, 1);
    let count = 1;
    this.sortOrderLst = [];
    this.template.stageDetails.forEach(x => {
      x.sortOrder = count;
      let srtOrd = { sortOrder: count }
      this.sortOrderLst.push(srtOrd)
      count = count + 1;
    });
  }

  addProcess(index) {

    let prSortOrd = this.template.stageDetails[index]?.processDetails?.length + 1
    this.process = new ProcessDetailModel({
      processDetailId: 0,
      stageDetailId: 0,
      processId: 0,
      name: null,
      processTypeId: null,
      apiTypeId: null,
      canModifyProcess: true,
      isFileAssigned: false,
      turnAroundTime: 0,
      fileMappingId: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      processes: this.processlst,
      attributeDetails: [],
      fileMapping: null,
      filteredFileMappingsList: this.filemappings,
      filteredSourcesList: this.service.apiTypes,
      sortOrder: prSortOrd
    });
    this.template.stageDetails[index].processDetails.push(this.process);

  }

  deleteProcess(stgIdx, prsIdx) {
    this.hasDataModified = true;
    let frnHrdId = this.template.stageDetails[stgIdx]?.processDetails[prsIdx]?.fileMappingId;
    if (frnHrdId != null || frnHrdId !== 0) {
      let count = this.template.stageDetails[stgIdx]?.processDetails?.filter(y => y.fileMappingId == frnHrdId).length;

      if (count == 1) {
        var frdrRcords = this.template.stageDetails[stgIdx]?.processDetails.find(x => x.fileMappingId == frnHrdId);
        if (frdrRcords != null) {
          let frdhrdindx = this.fileMappingFilter.indexOf(frdrRcords);
          this.fileMappingFilter.splice(frdhrdindx, 1);
        }
      }
    }

    this.template.stageDetails[stgIdx].processDetails.splice(prsIdx, 1);
    // Process sequence number re-ordering
    let count = 1
    this.template.stageDetails[stgIdx].processDetails.forEach(x => {
      x.sortOrder = count;
      count = count + 1
    })
    this.isAddLineageEnabled();
  }


  addAttribute(stgIdx, prsIdx) {

    this.attribute = new AttributeDetailModel({
      attributeDetailId: 0,
      attributeId: 0,
      processDetailId: 0,
      name: null,
      value: '',
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      attributes: this.atributeslst
    })
    this.template.stageDetails[stgIdx].processDetails[prsIdx].attributeDetails.push(this.attribute);
  }

  deleteAttribute(stgIdx, prsIdx, att) {
    this.hasDataModified = true;
    this.template.stageDetails[stgIdx].processDetails[prsIdx].attributeDetails.splice(att, 1);

  }

  onSubmit() {

    if (this.isValidated()) {

      if (this.template.templateId == 0 && !this.template.isExperimentTemplateModel) {

        this.service.SaveTemplateInfo(this.template).subscribe(result => {
          this._messageService.filter('refresh');
          this.toastr.success('Save template successful!!');
          this.router.navigate(['/Templates']);
        },
          error => {
            this.toastr.error('Save template failed !!');
          });

      }
      else {
        this.checkFileMappChange();
        if (this.fileMappOriginalName != null) {
          let dialogConfig = this.service.getDialogConfig();
          let headerMessage = "Warning";
          dialogConfig.panelClass = "dialog-responsive";
          let message = "File mapping(s) " + this.fileMappOriginalName + " already have file(s) associated. Changing the mapping(s) will remove the associated file(s). Do you want to proceed?";
          dialogConfig.data = { message: message, headerMessage: headerMessage };
          let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(
            status => {
              if (status) {

                this.experimentService.deactivateFileDetails(this.processDetailModelLst)
                  .subscribe(result => {
                    this.templateUpdate();
                    this._messageService.filter('refresh');
                    this.toastr.success('File deleted successful!!');
                  });

              }
            });
        }
        else {

          this.templateUpdate();
        }

      }
    }
    else {
      let dialogConfig = this.service.getValidationDialogConfig();
      let headerMessage = "Error";
      let messageType = "Info"

      dialogConfig.data = { message: this.validationErrorTxt, headerMessage: headerMessage, messageType: messageType };
      this.dialog.open(StatusDialogComponent, dialogConfig);
    }
  }

  checkTemplateUsedInExperiment(templateId) {
    let status = false;
    this.service.checkTemplateUsedInExperiment(templateId).subscribe(
      status => {
        if (status != undefined && status == true) {
          this.isProjectGroupAssigned = true;

        }
      });
    return status;
  }

  templateUsedInExperimentPopup() {
    let dialogConfig = this.service.getDialogConfig();
    let headerMessage = "Info";
    let messageType = "Info"
    let _message = "An experiment with this Template has already been created.  You cannot change the Project/Group at this point";
    dialogConfig.data = { message: _message, headerMessage: headerMessage, messageType: messageType };
    this.dialog.open(StatusDialogComponent, dialogConfig);
    this.editTemplateInfo(this.template.templateId)
    
  }

  templateUpdate() {
    if (this.fromExperiment == 0) { 

      if (this.isProjectGroupAssigned && (this.selectedProjectIdOld != this.template.projectId || this.selectedGroupIdOld != this.template.groupId)) {       
        this.templateUsedInExperimentPopup();       
        return;
      }
      
      this.service.updateTemplate(this.template).subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('Update template successful!!');
        this.router.navigate(['/Templates']);
      },
        error => {
          this.toastr.error('Update template failed !!');
        });
    }
    else {
      this.experimentService.UpdateExperimentTemplate(this.template).subscribe(result => {
        this._messageService.filter('refresh');
        this.toastr.success('Update experiment template successful!!');
        this.router.navigate(['/ExperimentInformation', this.templateId]);
      },
        error => {
          this.toastr.error('Update experiment template failed !!');
        });
    }
  }
  checkFileMappChange() {
    this.template.stageDetails.forEach(element => {
      element.processDetails?.forEach(prs => {
        this.templateOriginal?.stageDetails.filter(y => y.stageDetailId == element.stageDetailId).forEach(d => {
          d.processDetails?.filter(f => f.processDetailId == prs.processDetailId).forEach(s => {
            if (s.fileMappingId != prs.fileMappingId && s.isFileAssigned) {
              let fileterdFrdHeader = this.filemappings?.find(h => h.fileMappingId == s.fileMappingId);
              if (this.fileMappOriginalName != null && fileterdFrdHeader != undefined) {
                this.processDetailModelLst.push(s);
                this.fileMappOriginalName = this.fileMappOriginalName + "," + fileterdFrdHeader.name;
              }
              else if (fileterdFrdHeader !== undefined) {
                this.processDetailModelLst.push(s);
                this.fileMappOriginalName = fileterdFrdHeader.name;
              }
            }
          });
        });
      });
    });
  }

  isValidated() {
    let result = true;
    this.isError = false;
    this.validationErrorTxt = "";
    let stgErr = '';
    let stgDuplErr = '';
    let prsErr = '';
    let prsDuplErr = '';
    let attErr = '';
    let attDuplErr = '';
    let attValMis = '';
    let scanUpld = '';
    let plnnHrs = '';
    let project = '';
    let group = '';
    let fileMap = '';
    let fileLineageMsg = '';
    let keyval = '';
    let srcDesFM = '';
    let srtOrd = '';
    let apiType = '';
    let multiOneRingErr = '';
    let bnxEntity = '';
    let bnxProjects = '';
    let bnxProcesses = '';

    if (this.template != undefined) {
      if (this.template.name == null || this.template.name == '') {
        result = false;
        this.isError = true;
        this.validationErrorTxt = " Please enter a valid template name.\n"
      }

      if (this.template.projectId == 0 || this.template.projectId == null) {
        result = false;
        this.isError = true;
        project = " Please select a project.\n"
      }
      if (this.template.groupId == 0 || this.template.groupId == null) {
        result = false;
        this.isError = true;
        group = " Please select a group.\n"
      }
      if (this.template.name != null && this.template.name != '') {

        var templateName = this.service.templates?.find(x => x.name.trim().toLowerCase() === this.template.name.trim().toLowerCase())

        if ((templateName != null || templateName != undefined) && templateName?.templateId == 0) {
          this.validationErrorTxt = "A template with the same name already exists.\n"
          result = false;
          this.isError = true;
        }
      }

      if (this.template.lineageDetails != null && this.template.lineageDetails.length > 0) {
        this.template.lineageDetails?.forEach(x => {

          if (x.sourceFileMappingId == x.destinationFileMappingId) {
            srcDesFM = "Please select unique source and destination file mappings.\n"
            result = false;
            this.isError = true;
          }
          let srcDestCount = this.template.lineageDetails.filter(a => a.sourceFileMappingId == x.sourceFileMappingId && a.destinationFileMappingId == x.destinationFileMappingId)?.length;

          if (x.sourceFileMappingId == null || x.sourceFileMappingId == 0) {
            fileLineageMsg = "Please select valid source file mapping.\n"
            result = false;
            this.isError = true;
          }
          if (x.destinationFileMappingId == null || x.destinationFileMappingId == 0) {
            fileLineageMsg = fileLineageMsg + "Please select valid destination file mapping.\n"
            result = false;
            this.isError = true;
          }
          if (srcDestCount > 1) {
            fileLineageMsg = "Duplicate file lineage found. Please select unique file lineage.\n"
            result = false;
            this.isError = true;
          }
          x.mappingKeyValues?.forEach(y => {
            if (y.sourceKeyId == null || y.sourceKeyId == 0) {
              keyval = "Please select valid source key.\n"
              result = false;
              this.isError = true;
            }
            if (y.destinationKeyId == null || y.destinationKeyId == 0) {
              keyval = keyval + "Please select valid destination key.\n"
              result = false;
              this.isError = true;
            }
            let srcDestCountKey = x.mappingKeyValues?.filter(a => a.sourceKeyId == y.sourceKeyId && a.destinationKeyId == y.destinationKeyId)?.length;

            if (srcDestCountKey > 1) {
              keyval = "Duplicate file lineage key found. Please select unique file lineage key value.\n"
              result = false;
              this.isError = true;
            }
          });
          if (this.template.lineageDetails?.filter(ln => ln.sortOrder == x.sortOrder).length > 1) {
            result = false
            this.isError = true;
            srtOrd = "Please select unique lineage sort order.\n"
          }
        });
      }

      if (this.template != undefined && this.template.stageDetails.length > 0) {
        let bnxProcessCount = 0;
        this.template.stageDetails?.forEach(stg => {
          if (stg.stageId == null || stg.name == '' || stg.name == null) {
            result = false
            this.isError = true;
            stgErr = " Please select/enter a stage name.\n"
          }

          if (this.template.stageDetails?.filter(x => x.name == stg.name).length > 1) {
            result = false
            this.isError = true;
            stgDuplErr = " The stage " + stg.name + " with same name already exists.\n"
          }

          if (this.template.stageDetails?.filter(x => x.sortOrder == stg.sortOrder).length > 1) {
            result = false
            this.isError = true;
            srtOrd = "Please select unique sort order.\n"
          }

          if (stg.processDetails != null && stg.processDetails.length > 0) {
            //Multi Bionexus process types not allowed in the template
            let bnxRegCount = stg.processDetails.filter(x => x.processTypeId === this.proccessType.BioNexus && x.apiTypeId === this.strainRegistrationApiId).length;

            let bnxGetCount = stg.processDetails.filter(x => x.processTypeId === this.proccessType.BioNexus && x.apiTypeId === this.getStrainInfoApiId).length;
            if (bnxRegCount > 1 || bnxGetCount > 1) {
              bnxProcessCount = bnxRegCount > 1 ? bnxProcessCount + bnxRegCount : bnxProcessCount + bnxGetCount;
              if (bnxProcessCount > 1) {
                result = false
                this.isError = true;
                bnxProcesses = bnxRegCount > 1 ? " You can not add more than one bionexus strain registration process to template.\n" : " You can not add more than one bionexus get strain information process to template.\n"
              }
            }

            stg.processDetails?.forEach(prs => {

              if (prs?.apiTypeId !== undefined && prs?.apiTypeId !== null && prs?.apiTypeId !== 0) {
                let bnxRegCount = 0;
                let bnxGetCount = 0;
                // check if any other stage has same BNX process type or not.
                this.template.stageDetails?.forEach(dp => {
                  bnxRegCount = bnxRegCount + dp.processDetails.filter(x => x.processTypeId === this.proccessType.BioNexus && x.apiTypeId === this.strainRegistrationApiId).length;

                  bnxGetCount = bnxGetCount + dp.processDetails.filter(x => x.processTypeId === this.proccessType.BioNexus && x.apiTypeId === this.getStrainInfoApiId).length;
                  if (((dp.stageDetailId === 0 && stg.stageDetailId === 0 && dp.sortOrder !== stg.sortOrder) || dp.stageDetailId !== stg.stageDetailId) && (bnxRegCount > 1 || bnxGetCount > 1)) {
                    bnxProcessCount = bnxRegCount > 1 ? bnxProcessCount + bnxRegCount : bnxProcessCount + bnxGetCount;
                    result = false
                    this.isError = true;
                    bnxProcesses = bnxRegCount > 1 ? " You can not add more than one bionexus strain registration process to template.\n" : " You can not add more than one bionexus get strain information process to template.\n"
                  }
                });
              }

              if ((prs?.processTypeId !== null || prs?.processTypeId === 0) && prs?.fileMappingId == null || prs?.fileMappingId == 0) {
                prsErr = " Please select a file mapping for " + prs?.name + " process.\n"
                result = false
                this.isError = true;
              }
              if (prs?.fileMappingId !== null && prs?.fileMappingId !== 0) {

                // check if any other stage has same file mapping or not.
                this.template.stageDetails?.forEach(dp => {
                  if (((dp.stageDetailId === 0 && stg.stageDetailId === 0 && dp.sortOrder !== stg.sortOrder) || dp.stageDetailId !== stg.stageDetailId) && dp.processDetails?.filter(x => x.fileMappingId !== 0 && x.fileMappingId === prs.fileMappingId).length > 0) {
                    let process = dp.processDetails?.filter(x => x.fileMappingId !== 0 && x.fileMappingId === prs.fileMappingId);
                    result = false
                    this.isError = true;
                    attErr = " The file mapping with same name already exists in " + process[0].name + ".\n"
                  }
                });
              }
              if (prs?.processTypeId != null && prs?.processTypeId == ProcessTypes.OneRing) {
                if (prs?.apiTypeId == undefined || prs?.apiTypeId == null) {
                  result = false
                  this.isError = true;
                  apiType = "Please select a source for " + prs?.name + ".\n"
                }
              }

              if (prs?.processTypeId != null && prs?.processTypeId == ProcessTypes.BioNexus) {
                if (prs?.apiTypeId == undefined || prs?.apiTypeId == null) {
                  result = false
                  this.isError = true;
                  apiType = "Please select a operation type for " + prs?.name + ".\n"
                }
                if (prs.apiTypeId == this.strainRegistrationApiId) {
                  if (prs?.bNXEntityTypeId == undefined || prs?.bNXEntityTypeId == null) {
                    result = false
                    this.isError = true;
                    bnxEntity = "Please select BNX Entity for " + prs?.name + ".\n"
                  }
                  if (prs?.bNXProjectId == undefined || prs?.bNXProjectId == null || prs.bNXProjectId.length == 0) {
                    result = false
                    this.isError = true;
                    bnxProjects = "Please select BNX Projects for " + prs?.name + ".\n"
                  }
                }

              }
              // check same stage if the file mapping is reused.
              // if (prs?.fileMappingId !== null && prs?.fileMappingId !== 0 && stg.processDetails?.filter(x => x.fileMappingId === prs.fileMappingId).length > 1) {
              //   let process = stg.processDetails?.filter(x => x.fileMappingId === prs.fileMappingId);
              //   result = false
              //   this.isError = true;
              //   attErr = " The file mapping with same name already exists for " + process[0].name + ".\n";
              // }
              if (stg.processDetails.filter(x => x.name == prs.name).length > 1) {
                result = false
                this.isError = true;
                prsDuplErr = " The process" + prs.name + " with same name already exists.\n"
              }
              //Multi One Ring process type not allowed in stage , each stage can have one "One Ring" Process Type
              if (stg.processDetails.filter(x => x.processTypeId == this.proccessType.OneRing).length > 1) {
                result = false
                this.isError = true;
                multiOneRingErr = " You can not add more than one One Ring process to a Stage.\n"
              }
              if (prs.name == null || prs.name == '') {
                prsErr = " Please select/enter a process name.\n"
                result = false
                this.isError = true;
              }

              if (prs.turnAroundTime == null) {
                plnnHrs = " Please enter valid turn around time.\n";
                result = false
                this.isError = true;
              }
              if (prs.attributeDetails != null && prs.attributeDetails != undefined && prs.attributeDetails.length > 0) {
                prs.attributeDetails?.forEach(atr => {
                  if (prs.attributeDetails.filter(x => x.name == atr.name).length > 1) {
                    result = false
                    this.isError = true;
                    attErr = " The attribute " + atr.name + " with same name already exists.\n"
                  }

                  if ((atr.name == null || atr.name == '')) {
                    result = false
                    this.isError = true;
                    attDuplErr = " Please select/enter a attribute name.\n";
                  }
                });
              }

            });
          }

        });
      }
    }
    this.validationErrorTxt = this.validationErrorTxt + group + project + stgErr + stgDuplErr +
      prsErr + prsDuplErr + attErr + attDuplErr + attValMis + scanUpld + plnnHrs
      + fileMap + fileLineageMsg + keyval + srcDesFM + srtOrd + apiType + multiOneRingErr + bnxEntity + bnxProjects + bnxProcesses;
    return result;
  }

  public onChangeStage(event: string, stgIdx) {
    this.valueChange();
    this.template.stageDetails[stgIdx].stages = this.stageslst.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));

  }
  public onChangeProcess(event: string, stgidx, prsIdx) {
    this.valueChange();
    this.template.stageDetails[stgidx].processDetails[prsIdx].processes = this.processlst.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));

  }

  public onChangeFileMapping(event: string, stgidx, prsIdx) {
    this.template.stageDetails[stgidx].processDetails[prsIdx].filteredFileMappingsList = this.filemappings.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));

  }

  public onChangeSources(event: string, stgidx, prsIdx) {
    this.template.stageDetails[stgidx].processDetails[prsIdx].filteredSourcesList = this.apiTypes.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));

  }

  public onChangeAttribute(event: string, stgidx, prsIdx, atrIdx) {
    this.valueChange();
    this.template.stageDetails[stgidx].processDetails[prsIdx].attributeDetails[atrIdx].attributes = this.atributeslst.filter(item => item.name.toLowerCase().includes(event.toLowerCase()));

  }


  onSelectSourceChange(apiTypeId, stgidx, prsIdx) {
    this.valueChange();
  }
  convert<T>(array: Array<T>): Observable<Array<T>> {
    return of(array);
  }

  isPlannedhrsShow: boolean = false;
  hidshowPlannedHrs() {
    this.isPlannedhrsShow = !this.isPlannedhrsShow;
  }

  onCopy() {
    this.valueChange();
    this.isTemplateNameDisabled = false;
    this.isCopyButtonVisible = false;
    this.isdataCopy = true;
    this.template.templateId = 0
    this.template.name = "";
    this.template.description = ""
    this.selectedProjectId = this.template.projectId;
    this.template.stageDetails.forEach(x => {
      x.processDetails.forEach(y => {
        let fileMap = this.filemappings.find(x => x.fileMappingId == y.fileMappingId)
        if (fileMap == null) {
          y.fileMappingId = null;

        }
      })
    })
    this.popupHeader();
  }

  isShow = false;
  toggleDisplayStg() {
    this.isShow = !this.isShow;
  }

  isShowPrc = false;
  toggleDisplayProc() {
    this.isShowPrc = !this.isShowPrc;
  }

  popupHeader() {
    let result = this.componentNameNew;
    if (this.template != undefined && this.template != undefined) {
      result = this.template?.templateId != 0 ? this.componentNameModify : this.componentNameNew;
      if (this.isdataCopy == true) {
        result = this.componentNameNew
      }
    }
    return result
  }

  displayInformation() {
    let dialogConfig = this.service.getDialogConfig();
  }

  onCancel() {
    if (this.fromExperiment == 1) {
      this.router.navigate(['/ExperimentInformation', this.templateId]);
    }
    else {
      this.router.navigate(['/Templates']);
    }

  }

  showPlandHsr: boolean = false;

  IsScanValue() {
    this.showPlandHsr = true;

  }
  IsUploadValue() {
    this.showPlandHsr = false;
  }

  originalprocessTypeId = 0;
  previousProcessDetailId = 0;
  onProcessTypeChange(processTypeId, stgIdx, prsIdx) {
    this.valueChange();

    this.template.stageDetails[stgIdx].processDetails[prsIdx].apiTypeId = null;
    this.template.stageDetails[stgIdx].processDetails[prsIdx].fileMappingId = null;

    if(processTypeId === ProcessTypes.FieldConcatenation)
      this.template.stageDetails[stgIdx].processDetails[prsIdx].apiTypeId = this.joinDataApiId;

    //Check if the process is an existing process
    if (this.templateOriginal !== undefined && this.templateOriginal?.stageDetails[stgIdx] !== undefined && this.templateOriginal?.stageDetails[stgIdx].processDetails[prsIdx] !== undefined) {
      //check if the change is for same process
      this.previousProcessDetailId = this.templateOriginal.stageDetails[stgIdx].processDetails[prsIdx].processDetailId;
      if (this.template.stageDetails[stgIdx]?.processDetails[prsIdx]?.processDetailId !== this.templateOriginal.stageDetails[stgIdx]?.processDetails[prsIdx]?.processDetailId ||
        this.template.stageDetails[stgIdx]?.processDetails[prsIdx]?.processTypeId !== this.templateOriginal.stageDetails[stgIdx]?.processDetails[prsIdx]?.processTypeId) {
        this.originalprocessTypeId = this.templateOriginal.stageDetails[stgIdx].processDetails[prsIdx].processTypeId;
      }
      else {
        this.originalprocessTypeId = this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId;
      }
    }
    else {
      // Flow for new stage or process
      if (this.previousProcessDetailId !== 0) {
        this.originalprocessTypeId = 0;
        this.previousProcessDetailId = 0;
      }

      if (processTypeId === null) {
        this.originalprocessTypeId = 0;
      }
      else if (this.originalprocessTypeId === null) {
        this.originalprocessTypeId = this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId !== ProcessTypes.OneRing ? this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId : 0;
      }
    }

    //Experiment edit -template edit not allowed to add On Ring Process
    if (this.fromExperiment == 1 && processTypeId == this.proccessType.OneRing && (this.template.stageDetails[stgIdx]?.processDetails[prsIdx]?.processDetailId !== this.templateOriginal.stageDetails[stgIdx]?.processDetails[prsIdx]?.processDetailId || this.template.stageDetails[stgIdx]?.processDetails[prsIdx]?.processTypeId !== this.templateOriginal.stageDetails[stgIdx]?.processDetails[prsIdx]?.processTypeId)) {
      let dialogConfig = this.service.getValidationDialogConfig();
      let msg = "One Ring process types are only available for selection during experiment creation.";
      let headerMessage = "Error";
      let messageType = "Info"
      dialogConfig.data = { message: msg, headerMessage: headerMessage, messageType: messageType };
      let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        status => {
          if (status) {

            if (this.templateOriginal.stageDetails[stgIdx] !== undefined && this.templateOriginal.stageDetails[stgIdx].processDetails[prsIdx] !== undefined) {
              this.originalprocessTypeId = this.templateOriginal.stageDetails[stgIdx].processDetails[prsIdx].processTypeId;
              this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId = this.originalprocessTypeId;
            }
            else {
              this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId = this.originalprocessTypeId;
              this.originalprocessTypeId = null;
            }
            this.isAddLineageEnabled();
          }
        });

    }
    else {
      if (this.templateOriginal !== undefined && this.templateOriginal?.stageDetails[stgIdx] !== undefined && this.templateOriginal?.stageDetails[stgIdx].processDetails[prsIdx] !== undefined) {
        this.originalprocessTypeId = this.templateOriginal.stageDetails[stgIdx].processDetails[prsIdx].processTypeId;
      }
      else if (processTypeId !== null) {
        this.originalprocessTypeId = this.template.stageDetails[stgIdx].processDetails[prsIdx].processTypeId;
      }
    }
    this.isAddLineageEnabled();

  }


  addFileLinkage() {
    let lineageSortOrder = this.template.lineageDetails?.length + 1;
    let srtOrdLineage = { sortOrder: lineageSortOrder };
    this.sortOrderLineage.push(srtOrdLineage);
    this.flLineage = new LineageDetailModel({
      lineageDetailId: 0,
      templateId: null,
      sourceFileMappingId: null,
      destinationFileMappingId: null,
      isActive: true,
      createdDate: new Date(),
      createdBy: null,
      modifiedDate: new Date(),
      modifiedBy: null,
      sortOrder: lineageSortOrder,
      mappingKeyValues: [],
      userFriendlyHeaderSourceKey: [],
      userFriendlyHeaderDestinationKey: [],

    });

    this.fmKeys = new FileMappingKeyValueModel({
      sourceKeyId: null,
      destinationKeyId: null,
    });
    this.flLineage.mappingKeyValues.push(this.fmKeys);
    this.template.lineageDetails.push(this.flLineage);
  }
  
  addKey(index) {
    this.template.lineageDetails[index].mappingKeyValues.push(new FileMappingKeyValueModel({
      sourceKeyId: null,
      destinationKeyId: null,
    }));
  }

  deleteMaping(index) {
    this.valueChange();
    this.template.lineageDetails.splice(index, 1);
    let count = 1;
    this.sortOrderLineage = [];
    this.template.lineageDetails.forEach(x => {
      x.sortOrder = count;
      let srtOrdLineage = { sortOrder: count };
      this.sortOrderLineage.push(srtOrdLineage);
      this.sourceKeySubFrindlyHeaders(x.sourceFileMappingId, count - 1);
      this.destinationKeySubFrindlyHeaders(x.destinationFileMappingId, count - 1);
      count = count + 1;
    });
  }
  deleteKey(mpInx, kyInx) {
    this.template.lineageDetails[mpInx].mappingKeyValues.splice(kyInx, 1);
  }
  loadFriendlyHeaders() {

    this.service.getFrindlyHeaders()
      .subscribe(
        result => {
          this.fndrlHeaders = result;
        });

  };
  sourceKeySubFrindlyHeaders(fileMappingId, mpIndx) {
    this.service.getFileMappingById(fileMappingId)
      .subscribe(
        result => {
          this.filemapping = result;
          this.valueChange();
          this.template.lineageDetails[mpIndx].userFriendlyHeaderSourceKey = [];
          this.addPreLineageKeys(mpIndx, true);
          this.filemapping.mappingRules.forEach(x => {
            if (x.isKey) {
              this.updateFriendlyHeadersList(mpIndx, x.headerId, true);
            }
            x.subMappingRules.forEach(y => {
              if (y.isKey) {
                this.updateFriendlyHeadersList(mpIndx, y.headerId, true);
              }
              if (y.isFirstHeaderKey) {
                this.updateFriendlyHeadersList(mpIndx, y.firstHeaderId, true);
              }
            });
          });
        });
  }

  destinationKeySubFrindlyHeaders(fileMappingId, mpIndx) {
    this.service.getFileMappingById(fileMappingId)
      .subscribe(
        result => {
          this.valueChange();
          this.filemapping = result;
          this.template.lineageDetails[mpIndx].userFriendlyHeaderDestinationKey = [];
          this.addPreLineageKeys(mpIndx, false);
          this.filemapping.mappingRules.forEach(x => {
            if (x.isKey) {
              this.updateFriendlyHeadersList(mpIndx, x.headerId);
            }
            x.subMappingRules.forEach(y => {
              if (y.isKey) {
                this.updateFriendlyHeadersList(mpIndx, y.headerId);
              }
              if (y.isFirstHeaderKey) {
                this.updateFriendlyHeadersList(mpIndx, y.firstHeaderId);
              }
            });
          });

        });
  }

  private updateFriendlyHeadersList(mpIndx: any, headerId, isSourceList = false) {
    let check = isSourceList ? this.template.lineageDetails[mpIndx].userFriendlyHeaderSourceKey : this.template.lineageDetails[mpIndx].userFriendlyHeaderDestinationKey;
    let tempcheck = null;
    if (check != undefined || check.length > 0) {
      tempcheck = isSourceList ? this.template.lineageDetails[mpIndx].userFriendlyHeaderSourceKey.find(z => z.headerId == headerId) : this.template.lineageDetails[mpIndx].userFriendlyHeaderDestinationKey.find(z => z.headerId == headerId);
    }

    if (tempcheck == undefined || tempcheck == null) {
      this.frdData = this.fndrlHeaders.find(a => a.headerId == headerId);
      isSourceList ? this.template.lineageDetails[mpIndx].userFriendlyHeaderSourceKey.push(this.frdData) : this.template.lineageDetails[mpIndx].userFriendlyHeaderDestinationKey.push(this.frdData);
    }
  }

  private addPreLineageKeys(mpIndx: any, isSourceList = false) {
    if(mpIndx > 0) {
      for (let i = 0; i <= mpIndx; i++) {
        let keysList = isSourceList ? this.template.lineageDetails[i].userFriendlyHeaderSourceKey : this.template.lineageDetails[i].userFriendlyHeaderDestinationKey;
        keysList.forEach(x=> {
            this.updateFriendlyHeadersList(mpIndx,x.headerId,isSourceList);
        });
      }
    }
  }

  onFileMappingChange(status: boolean) {
    if (status) {
      this.hasDataModified = true;
    }
    this.fileMappingFilter = [];
    this.template.stageDetails.forEach(x => {
      x.processDetails.filter(z => z.processTypeId != null).forEach(y => {
        if (y.fileMappingId !== undefined) {
          var frndHeadr = new FileMappingModel;
          frndHeadr = this.filemappings?.find(x => x.fileMappingId !== undefined && x.fileMappingId == y.fileMappingId);
          if (frndHeadr !== undefined && this.fileMappingFilter.filter(x => x.fileMappingId == frndHeadr?.fileMappingId).length == 0) {
            this.fileMappingFilter.push(frndHeadr);
          }
        }
      });
      this.isAddLineageEnabled();
    });
    if (this.fileMappingFilter.length > 0) {
      this.checkFileLineageMapp();
    }
  }

  checkFileLineageMapp() {
    if (this.template?.lineageDetails != undefined) {
      this.template?.lineageDetails.forEach(z => {

        if (this.fileMappingFilter.filter(x => x.fileMappingId === z.sourceFileMappingId).length == 0) {
          z.sourceFileMappingId = 0;
          z.mappingKeyValues?.forEach(v => {
            v.sourceKeyId = 0;
          });
        }
        if (this.fileMappingFilter.filter(x => x.fileMappingId === z.destinationFileMappingId).length == 0) {
          z.destinationFileMappingId = 0;
          z.mappingKeyValues?.forEach(v => {
            v.destinationKeyId = 0;
          });
        }
      });
    }
  }

  isAddLineageEnabled() {
    let isMultiFileMap = 0;
    this.template.stageDetails?.forEach(x => {
      if (x.processDetails?.filter(a => a.fileMappingId != 0 && a.fileMappingId != null).length > 1) {
        isMultiFileMap = 1;
      }
    });

    if (this.template.stageDetails?.filter(x => x.processDetails?.find(s => s.fileMappingId != 0 && s.fileMappingId != null)).length > 1 || isMultiFileMap == 1) {
      this.isAddLineageBtnDisabled = false;
    }
    else {
      this.isAddLineageBtnDisabled = true;
    }
  }

  checkMissingFileMapping(fileMappingId) {
    let frndHeadr = this.filemappings?.find(x => x.fileMappingId !== undefined && x.fileMappingId == fileMappingId);
    if (frndHeadr == undefined && frndHeadr == null) {
      this.isPopupForFileMappingMissing = true;
      let flnName = this.filemappingsAll.find(aa => aa.fileMappingId == fileMappingId)?.name
      if (flnName != "" && flnName != undefined) {
        let fndFlMpNmActive = this.filemappingsAll.find(bb => bb.name == flnName && bb.isActive == true)?.name;

        if (fndFlMpNmActive != undefined && fndFlMpNmActive != "") {
          this.fileMappingActiveNames = this.fileMappingActiveNames != null ? this.fileMappingActiveNames + fndFlMpNmActive : fndFlMpNmActive;
        }
        else if (flnName != undefined && flnName != "") {
          this.fileMappingDeActiveNames = this.fileMappingDeActiveNames != null ? this.fileMappingDeActiveNames + flnName : flnName;
        }
      }
    }
  }

  checkFileMapDeactivated() {
    let msg = null;
    if (this.isPopupForFileMappingMissing) {
      let msg1 = "The file mapping(s) " + this.fileMappingActiveNames + " have a newer version available. Please re-select the file mapping(s) and lineage details.";
      let msg2 = "The file mapping(s) " + this.fileMappingDeActiveNames + " are marked as inactive. Please re-select the file mapping(s) and lineage details."
      if (this.fileMappingActiveNames != null && this.fileMappingActiveNames != "") {
        msg = msg1
      }
      else if (this.fileMappingDeActiveNames != null && this.fileMappingDeActiveNames != "") {
        msg = msg2
      }

      if (this.fileMappingActiveNames != null && this.fileMappingActiveNames != "" && this.fileMappingDeActiveNames != null && this.fileMappingDeActiveNames != "") {
        msg = msg1 + "\n "
        msg = msg + msg2;
      }
      if (msg !== null) {
        let dialogConfig = this.service.getDialogConfig();
        let headerMessage = "Error";
        let messageType = "Info"
        dialogConfig.data = { message: msg, headerMessage: headerMessage, messageType: messageType };
        this.dialog.open(StatusDialogComponent, dialogConfig);
      }
    }
  }

  loadProjects() {

    if (this.fromExperiment == 0) {
      this.projectsService.getProjectsForAdminUser().subscribe(
        result => {
          this.service.projectsModel = result.filter(x => x.isActive);;
          this.projects = this.service.projectsModel;
        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      this.projectsService.GetProjectsForAnalystAndObserverUser().subscribe(
        result => {
          this.service.projectsModel = result.filter(x => x.isActive);;
          this.projects = this.service.projectsModel;
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  loadGroups(projectid) {
    this.groupService.getGroupsByProjectId(projectid, false).subscribe(
      result => {
        this.service.groupsModel = result.filter(x => x.isActive);
        this.groups = this.service.groupsModel;
      },
      error => {
        console.log(error);
      }
    );
  }

  GetProcessTypes() {
    this.service.GetProcessTypes().subscribe(
      result => {
        this.processTypes = result;
        this.service.processTypes = result;

        this.processTypes.unshift(this.service.processType);
      },
      error => {
        console.log(error);
      }
    );
  }



  loadStages() {
    this.service.GetStages().subscribe(
      result => {

        this.service.stageModel.stages = result.filter(x => x.isActive);;
        this.stageslst = this.service.stageModel?.stages;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadProcesses() {
    this.service.GetProcesses().subscribe(
      result => {

        this.service.processModel.processes = result.filter(x => x.isActive);
        this.processlst = this.service.processModel?.processes;

      },
      error => {
        console.log(error);
      }
    );
  }

  loadAttributes() {
    this.service.GetAttributes().subscribe(
      result => {

        this.service.attributesModel.attributes = result.filter(x => x.isActive);
        this.atributeslst = this.service.attributesModel?.attributes;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadBionexusEntities() {
    this.service.GetBionexusEntities().subscribe(
      result => {
        this.bionexusEntities = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadBionexusProjects() {
    this.service.GetBionexusProjects().subscribe(
      result => {
        this.bionexusProjects = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadOperationTypes() {
    this.service.GetOperationTypes().subscribe(
      result => {
        this.operationTypes = result.filter(x => x.name !== AppConstants.JoinData);
        this.strainRegistrationApiId = result.find(x=>x.name === AppConstants.StrainRegistration).apiTypeId;
        this.getStrainInfoApiId = result.find(x=>x.name === AppConstants.GetStrainInfo).apiTypeId;
        this.joinDataApiId = result.find(x=>x.name === AppConstants.JoinData).apiTypeId;
      },
      error => {
        console.log(error);
      }
    );
  }

  onOperationTypeChange(processDetails) {
    this.valueChange();
    if (processDetails.apiTypeId == this.getStrainInfoApiId) {
      processDetails.bNXEntityTypeId = 0;
      processDetails.bNXProjectId = [];
    }
  }

  onBnxEntityChange(bNXEntityTypeId, stgidx, prsIdx) {
    this.valueChange();
  }
  onBnxProjectsChange(bNXProjectId, stgidx, prsIdx) {
    this.valueChange();
  }

  compareBionexusProjectsFn: ((f1: any, f2: any) => boolean) | null = this.compareBionexusProjectsByValue;

  compareBionexusProjectsByValue(f1: any, f2: any) {
    return f1 && f2 && f1 == f2;
  }

}



